<template>
  <div class="wrapper">

    <div class="list">
      <div class="item" v-for="item in list" :key="item.image">
        <img :src="item.image" alt="">
      </div>
    </div>

    <div class="menu">
      <img class="bg" src="@/assets/monitor/menu-bg.png" alt="">
      <div class="content">
        <div class="title">设备列表</div>
        <div class="item" v-for="item in list" :key="item.image">
          <img class="button" src="@/assets/monitor/button-bg.png" alt="">
          <span>
            {{ item.title }}
          </span>
        </div>
      </div>

    </div>


  </div>
</template>
<script>
import test1 from '@/assets/monitor/1.png'
import test2 from '@/assets/monitor/2.png'
import test3 from '@/assets/monitor/3.png'
import test4 from '@/assets/monitor/4.png'

export default {
  data() {
    return {
      list: [
        {
          title: '摄影机1号',
          image: test1,
        },
        {
          title: '摄影机2号',
          image: test2,
        },
        {
          title: '摄影机3号',
          image: test3,
        },
        {
          title: '摄影机4号',
          image: test4,
        }
      ]
    };
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 2em;
}

.list {
  padding-top: 4vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;

  .item {
    img {
      width: 100%;
    }
  }
}

.menu {
  position: relative;


  .bg {
    width: 20vw;
  }

  .content {
    position: absolute;
    top: 5vh;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 2rem;
    color: #00FFFF;
    margin-bottom: .6em;
  }


  .item {
    position: relative;
    margin-bottom: 1em;


    .button {
      width: 10vw;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      font-size: 1.2rem;
      font-weight: 400;
      color: #00FFFF;
    }
  }
}
</style>
